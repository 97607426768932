<template>
  <div class="c-ui-datatable">
    <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
      <NumberResults v-model="numberOfResults" :showed-results="true" />
      <div class="tw-flex tw-flex-wrap tw-justify-start tw-gap-4">
        <UIInput
          :model-value="searchTerm"
          @update:model-value="onSearchTermChange"
          id="search"
          name="search"
          ui-class="tw-flex tw-relative !tw-mt-0"
          :label="$t('common.search')"
          label-on-border
          input-group-class="with-icon !tw-w-[230px] !tw-mb-5 !tw-mt-0 tw-mr-2"
        >
          <font-awesome-icon
            icon="icon fa-solid fa-magnifying-glass"
            class="tw-absolute !tw-top-3.5 tw-right-5"
          />
        </UIInput>

        <div class="tw-flex tw-flex-wrap tw-justify-start tw-gap-4">
          <slot name="top-right" />

          <UIListbox
            :calculate-width="true"
            v-for="dropdown in dropdowns"
            :key="`${dropdown.key}_${randomId()}`"
            :options="dropdown.columns"
            :label="dropdown.label"
            :modelValue="dropdownsState[dropdown.label]"
            @update:modelValue="(e: string) => handleDropdownsChange(e, dropdown.key)"
          />

          <AccessContainer
            :disabled-permission="permission === null"
            :permission="permission"
          >
            <UIButton
              v-if="addTitle && isShowAddButton"
              type="primary"
              :label="addTitle"
              class="tw-self-center"
              size="medium"
              @click="$emit('createNew')"
            >
              <template #left>
                <font-awesome-icon
                  icon="icon fa-solid fa-plus"
                  :class="{ icon: true }"
                  class="tw-mr-2 tw-text-xs"
                />
              </template>
            </UIButton>
          </AccessContainer>
        </div>
      </div>
    </div>
    <AccessContainer
      :disabled-permission="permissionShowTable === null"
      :permission="permissionShowTable"
    >
      <DataTableComponent
        ref="tableRef"
        class="dataTable dataTable no-footer table table-responsive"
        :class="{ cursorDefault: isCursorDefault }"
        :columns="columns"
        :options="optionsFilled"
      >
        <template #action="props">
          <slot name="button" v-bind="props" />
        </template>
      </DataTableComponent>
    </AccessContainer>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Watch, Prop } from 'vue-facing-decorator'
import DataTableComponent from 'datatables.net-vue3'
import DataTablesCore, { type ConfigColumns } from 'datatables.net-bs5'
import UIButton from '@/components/UI/UIButton.vue'
import NumberResults from '@/components/common/NumberOfResults.vue'
import UIInput from '@/components/UI/UIInput.vue'

import type { Nullable } from '@/types/Nullable'
import type { Dropdown } from '@/types/DatatableTypes'
import type { Config } from 'datatables.net-bs5'

import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons'
import 'datatables.net-buttons-bs5'
import type { IPermArray } from '@/types/PermissionsModules'
import UIListbox from '@/components/UI/UIListbox.vue'
import { userService } from '@/main'
import debounce from 'lodash/debounce'

@Component({
  components: {
    UIListbox,
    DataTableComponent,
    NumberResults,
    UIButton,
    UIInput,
  },
  emits: ['createNew', 'select'],
  expose: ['tableRef'],
})
export default class UIDatatable extends Vue {
  @Prop({ required: true }) columns!: ConfigColumns
  @Prop({ required: true }) options!: Config
  @Prop({ required: false }) addTitle!: string
  @Prop({ required: false }) dropdowns!: Dropdown[]
  @Prop({ required: false }) isCursorDefault!: boolean
  @Prop({ required: false, default: true }) isShowAddButton!: boolean

  // Hack to force redraw of the table
  @Prop({ required: false }) public redraw!: number
  @Prop({ required: false, default: null }) permission!: Nullable<IPermArray>
  @Prop({ required: false, default: null })
  public permissionShowTable!: Nullable<IPermArray>
  @Prop({ required: false, default: '' }) public initSearchTerm!: string

  @Ref('tableRef')
  // eslint-disable-next-line
  public tableRef!: any

  public dropdownsState: Record<string, string | number> = {}
  public debounceSearch: Nullable<() => void> = null

  public optionsFilled: Nullable<Config> = null

  public searchTerm: string = ''
  public numberOfResults: number = 10

  @Watch('numberOfResults')
  public onNumberOfResultsChange(): void {
    this.tableRef.dt.page.len(this.numberOfResults).draw()
  }

  public onSearchTermChange(newSearchTerm: string): void {
    this.searchTerm = newSearchTerm
    if (this.debounceSearch) {
      this.debounceSearch()
    }
  }

  public searchTable(): void {
    this.tableRef.dt.search(this.searchTerm).draw()
  }

  public randomId(): string {
    return Math.random().toString(36).substring(7)
  }

  public beforeMount(): void {
    DataTableComponent.use(DataTablesCore)

    this.optionsFilled = this.options

    this.dropdowns?.forEach((dropdown) => {
      this.dropdownsState[dropdown.label] = dropdown.defaultState || ''
    })
  }

  public mounted(): void {
    // we would lose `this` context for the table
    // without doing this
    const searchTableMethod = this.searchTable
    this.debounceSearch = debounce((): void => {
      searchTableMethod()
    }, 500)

    // making sure that table methods are not called if user has no access
    // cause otherwise it would throw an error
    if (this.permissionShowTable) {
      const canAccess = userService.checkAccess(this.permissionShowTable)
      if (!canAccess) {
        return
      }
    }

    const table = this.tableRef?.dt
    if (!table) return

    // eslint-disable-next-line
    table.on('click', (e: any) => {
      let data = table.row(e.target.closest('tr')).data()
      this.$emit('select', data)
    })

    // case where we have cached search term
    // from local storage
    const defaultSearch = this.tableRef.dt.search() as string
    if (defaultSearch) {
      this.searchTerm = defaultSearch
    }
    if (this.searchTerm !== this.initSearchTerm) {
      this.tableRef.dt.search(this.initSearchTerm).draw()
      this.searchTerm = this.initSearchTerm
    }

    if (this.dropdowns?.length <= 0) return

    const labels = [] as string[]
    this.dropdowns?.forEach((dropdown) => {
      labels.push(dropdown.label)
    })

    const ids = [] as { label: string; id: number }[]
    this.tableRef.dt.columns().every(function (col: number) {
      // @ts-ignore eslint-disable-next-line
      const column = this
      const headerLabel = column.header().textContent as string
      const isCorrectHeader = labels.includes(headerLabel)
      if (isCorrectHeader) {
        ids.push({ label: headerLabel, id: col })
      }
    })

    ids.forEach((item) => {
      const statusFilterColumn = this.tableRef.dt.column(item.id)
      const searchVal: string = statusFilterColumn.search()
      if (searchVal === '' || isNaN(Number(searchVal))) {
        this.dropdownsState[item.label] = searchVal
      } else {
        this.dropdownsState[item.label] = Number(searchVal)
      }
    })
  }

  public applyFilter(label: string): void {
    let colNum = null
    const statusName = label
    this.tableRef.dt.columns().every(function (col: number) {
      // @ts-ignore eslint-disable-next-line
      const column = this
      const isCorrectHeader = column.header().textContent === statusName

      if (isCorrectHeader) {
        colNum = col
      }
    })
    const statusFilterColumn = this.tableRef.dt.column(colNum)
    statusFilterColumn.search(this.dropdownsState[label]).draw()
  }

  public handleDropdownsChange(val: string, label: string): void {
    this.dropdownsState[label] = val
    this.applyFilter(label)
  }

  public redrawTable(): void {
    // false argument here preserves the page number
    this.tableRef.dt.draw(false)
  }

  @Watch('redraw')
  public redrawOnKeyUpdate(): void {
    this.redrawTable()
  }
}
</script>

<style lang="scss">
@import 'datatables.net-bs5';

.c-ui-datatable {
  // Fix for overflowing tooltips
  .dt-scroll-body {
    position: unset !important;
  }

  table.dataTable th.dt-type-numeric {
    text-align: left;
  }

  .table thead th {
    border-bottom: 0px;
  }

  .table > :not(:first-child) {
    border-top: 0px;
  }

  .table.cursorDefault tr {
    cursor: default !important;
  }

  // TODO: what about components that dont redirect anywhere
  table.table.dataTable > :not(caption) > tr {
    &:hover {
      background: var(--text-color);
      cursor: pointer;
    }
  }
}
</style>
