<template>
  <UIModal
    hide-footer
    :showModal="showModal"
    :blur="true"
    class="c-unActiveAccountModal"
  >
    <template v-slot:header>
      <div class="tw-mx-auto">
        <h1>{{ $t('common.permissions.unauthorized') }}</h1>
      </div>
    </template>
    <template v-slot:default>
      <div class="tw-my-2">
        <div class="tw-text-center">
          <p>{{ $t('common.permissions.unauthRoleDesc') }}</p>
          <div>
            <span>{{ $t('common.permissions.unauthRoleDesc2') }}</span>

            <RouterLink
              :to="{ name: routeNames.Dashboard }"
              class="tw-cursor-pointer tw-font-bold tw-text-brand"
              @click.prevent.stop="redirect"
            >
              {{ $t('common.permissions.unauthRoleDescLink') }}
            </RouterLink>
          </div>
        </div>
        <HeaderContext class="tw-mt-10" @contextChange="onChangeContext" />
        <p class="tw-mt-8 tw-text-center tw-text-xs">
          {{ $t('common.permissions.unauthRoleDesc3') }}
        </p>
      </div>
    </template>
    <template #footer> </template>
  </UIModal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIModal from '@/components/UI/UIModal.vue'
import HeaderContext from '@/components/layouts/header/HeaderContext.vue'
import UITransition from '@/components/UI/UITransition.vue'
import type { Nullable } from 'vitest'
import { environmentsManager, userService } from '@/main'
import { routeNames } from '@/router/types'

@Component({
  components: { UITransition, HeaderContext, UIModal },
  emits: ['accessGranted', 'redirect'],
})
export default class UnauthorizedRouteModal extends Vue {
  @Prop({ default: false, required: false }) public showModal!: boolean

  public handleClose(): void {
    this.$emit('accessGranted')
  }
  public redirect(): void {
    this.$emit('redirect')
  }
  public get routeNames(): typeof routeNames {
    return routeNames
  }

  public onChangeContext(): void {
    const perm = this.$route.meta?.permission as Nullable<string>
    if (perm && environmentsManager.PERMISSIONS_ENABLED !== 'false') {
      const permission = userService.menuItems[perm]
      if (permission.active) {
        this.handleClose()
      }
    }
  }
}
</script>
